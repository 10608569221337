@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'TTNormsPro';
  font-weight: regular;
  src: url(./assets/TTNormsPro-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'TTNormsPro';
  font-weight: bold;
  src: url(./assets/TTNormsPro-Bold.woff2) format('woff2');
}

html,
body {
  font-family: 'TTNormsPro';
}

.voyantic-button {
  @apply inline-flex flex-row justify-center items-center py-2 px-6 bg-gray-dark hover:bg-voyantic-orange text-white text-base rounded font-bold transition-all duration-150 ease-out hover:ease-in;
}
